const inititaState = {
  verticalMenu: []
}

export default (state = inititaState, action) => {
  switch (action.type) {
    case 'SET_VERTICAL_MENU':
      return { ...state, verticalMenu: action.payload }
    default:
      return inititaState
  }
}
