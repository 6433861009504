// ** Initial State
const initialState = {
  DebitNotes: [],
  DebitNoteDetail: {},
  totalPages: null,
  selectedDebitNote: null
}

const ViewDebitNoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DEBITNOTES':
      return {
        ...state,
        DebitNotes: action.data,
        totalPages: action.params
      }
    case 'GET_DEBITNOTE_DETAIL':
      return {
        ...state,
        DebitNoteDetail: action.data
      }
    case 'SELECTED_DEBITNOTE':
      return { ...state, selectedDebitNote: action.id }
    default:
      return state
  }
}

export default ViewDebitNoteReducer
