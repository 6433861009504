// ** Initial State
const initialState = {
  Quotes: [],
  QuoteDetail: {},
  totalPages: null,
  selectedQuote: null
}

const ViewQuoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_QUOTES':
      return {
        ...state,
        Quotes: action.data,
        totalPages: action.params
      }
    case 'GET_QUOTE_DETAIL':
      return {
        ...state,
        QuoteDetail: action.data
      }
    case 'SELECTED_QUOTE':
      return { ...state, selectedQuote: action.id }
    default:
      return state
  }
}

export default ViewQuoteReducer
