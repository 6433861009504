// ** Initial State
const initialState = {
  Customers: [],
  CustomerDetail: {},
  totalPages: null,
  selectedCustomer: null
}

const ViewCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CUSTOMERS':
      return {
        ...state,
        Customers: action.data,
        totalPages: action.params
      }
    case 'GET_CUSTOMER_DETAIL':
      return {
        ...state,
        CustomerDetail: action.data
      }
    case 'SELECTED_CUSTOMER':
      return { ...state, selectedCustomer: action.id }
    default:
      return state
  }
}

export default ViewCustomerReducer
