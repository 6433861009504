// ** Initial State
const initialState = {
  Bills: [],
  BillDetail: {},
  totalPages: null,
  selectedBill: null
}

const ViewBillReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BILL':
      return {
        ...state,
        Bills: action.data,
        totalPages: action.params
      }
    case 'GET_BILL_DETAIL' : 
      return {
        ...state,
        BillDetail: action.data
      }
    case 'SELECTED_BILL': 
      return {...state, selectedBill: action.id}
    default:
      return state
  }
}

export default ViewBillReducer
