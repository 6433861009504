const initialState = {
  companyOption: [],
  selectedCompanyId: ''
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_OPTIONS':
      return { ...state, companyOption: action.data }
    case 'SET_SELECTED_COMPANY':
      return { ...state, selectedCompanyId: action.selectedCompanyId }

    default:
      return state
  }
}

export default companyReducer
