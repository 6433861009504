// ** Initial State
const initialState = {
  Payments: [],
  PaymentDetail: {},
  totalPages: null,
  selectedPayment: null
}

const ViewPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PAYMENT':
      return {
        ...state,
        Payments: action.data,
        totalPages: action.params
      }
    case 'GET_PAYMENT_DETAIL' : 
      return {
        ...state,
        PaymentDetail: action.data
      }
    case 'SELECTED_PAYMENT': 
      return {...state, selectedPayment: action.id}
    default:
      return state
  }
}

export default ViewPaymentReducer
